import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Provider } from 'react-redux';
import store from '@/js/store';

import '@/scss/mobile/main.scss';
import '@/scss/desktop/main.scss';

const DesktopEntry = React.lazy(() => import('../containers/desktopEntry'));
const MobileEntry = React.lazy(() => import('../containers/mobileEntry'));

const ContentDesktop = React.lazy(() => import('@/js/containers/home/desktop'));
const ContentMobile = React.lazy(() => import('@/js/containers/home/mobile'));

document.documentElement.setAttribute('id', `${isMobile ? 'mb' : 'pc'}`);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={window.location.pathname || ''}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <BrowserView>
          <DesktopEntry>
            <ContentDesktop />
          </DesktopEntry>
        </BrowserView>
        <MobileView>
          <MobileEntry>
            <ContentMobile />
          </MobileEntry>
        </MobileView>
      </React.Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

import { combineReducers } from 'redux';
import { headerState } from './header';
import { categoriesState } from './categories';
import { subcategoryState } from './subcategory';
import { childCategoryState } from './childCategory';
import { searchState } from './search';
import { productState } from './product';

export default combineReducers({
  headerState,
  categoriesState,
  subcategoryState,
  childCategoryState,
  searchState,
  productState,
});

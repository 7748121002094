const initState = {
  sideMenu: {},
  cateBannerB2: {},
  cateBannerB3: {},
  cateBannerB4: {},
  cateBannerB5: {},
  cateBannerB6: {},
  cateBannerB7: {},
  cateBannerB8: {},
  cateBannerB9: {},
  cateBannerB10: {},
  cateBannerB11: {},
  cateBannerB12: {},
  cateBannerB13: {},
  cateBannerB14: {},
  cateBannerB15: {},
  cateBannerB16: {},
  cateBannerB17: {},
  cateBannerB18: {},
  cateBannerB19: {},
  cateBannerRecent: {},
  cateBannerSuggest: {},
  cateBannerRelated: {},
};

export function categoriesState(state = initState, action) {
  switch (action.type) {
    case 'GET_SIDE_MENU': {
      return {
        ...state,
        sideMenu: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B2': {
      return {
        ...state,
        cateBannerB2: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B3': {
      return {
        ...state,
        cateBannerB3: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B4': {
      return {
        ...state,
        cateBannerB4: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B5': {
      return {
        ...state,
        cateBannerB5: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B6': {
      return {
        ...state,
        cateBannerB6: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B7': {
      return {
        ...state,
        cateBannerB7: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B8': {
      return {
        ...state,
        cateBannerB8: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B9': {
      return {
        ...state,
        cateBannerB9: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B10': {
      return {
        ...state,
        cateBannerB10: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B11': {
      return {
        ...state,
        cateBannerB11: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B12': {
      return {
        ...state,
        cateBannerB12: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B13': {
      return {
        ...state,
        cateBannerB13: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B14': {
      return {
        ...state,
        cateBannerB14: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B15': {
      return {
        ...state,
        cateBannerB15: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B16': {
      return {
        ...state,
        cateBannerB16: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B17': {
      return {
        ...state,
        cateBannerB17: action.payload,
      };
    }
    case 'GET_CATE_BANNER_B18': {
      return {
        ...state,
        cateBannerB18: action.payload,
      };
    }
    case 'UPDATE_CATE_BANNER_B18': {
      const { device, index, data } = action.payload;
      const productKey = [device === 'pc' ? 'pc_products' : 'mb_products'];
      const productArray = state.cateBannerB18[productKey];
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        cateBannerB18: { ...state.cateBannerB18, [productKey]: productArray },
      };
    }
    case 'GET_CATE_BANNER_B19': {
      return {
        ...state,
        cateBannerB19: action.payload,
      };
    }
    case 'GET_CATE_BANNER_RECENT': {
      return {
        ...state,
        cateBannerRecent: action.payload,
      };
    }

    case 'UPDATE_CATE_BANNER_RECENT': {
      const { index, data } = action.payload;
      const productArray = state.cateBannerRecent.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        cateBannerRecent: { ...state.cateBannerRecent, products: productArray },
      };
    }

    case 'GET_CATE_BANNER_SUGGEST': {
      return {
        ...state,
        cateBannerSuggest: action.payload,
      };
    }
    case 'GET_CATE_BANNER_RELATED': {
      return {
        ...state,
        cateBannerRelated: action.payload,
      };
    }

    default:
      return state;
  }
}
export default categoriesState;

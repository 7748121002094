const initState = {
  bannerA2: [],
  bannerA3: [],
  bannerA4: [],
  cart: 0,
  like: 0,
  searchSuggest: {
    search: [],
    product: [],
    history: [
      { id: `searchHistory-1`, name: 'chair', link: '/' },
      { id: `searchHistory-2`, name: 'table', link: '/' },
    ],
  },
  account: {
    accountId: null,
    accountName: '',
    actionList: [],
  },
};

export function headerState(state = initState, action) {
  switch (action.type) {
    case 'GET_HOLA_BANNER_A2': {
      return {
        ...state,
        bannerA2: action.payload,
      };
    }

    case 'GET_HOLA_BANNER_A3': {
      return {
        ...state,
        bannerA3: action.payload,
      };
    }

    case 'GET_HOLA_Banner_A4': {
      return {
        ...state,
        bannerA4: action.payload,
      };
    }

    case 'GET_LIKE': {
      return {
        ...state,
        like: action.payload,
      };
    }

    case 'GET_CART': {
      return {
        ...state,
        cart: action.payload,
      };
    }

    case 'GET_SEARCH_SUGGEST': {
      return {
        ...state,
        searchSuggest: {
          ...state.searchSuggest,
          ...action.payload,
        },
      };
    }

    case 'GET_ACCOUNT': {
      return {
        ...state,
        account: action.payload,
      };
    }

    case 'SET_LOGOUT': {
      return {
        ...state,
        account: action.payload,
      };
    }

    case 'CLEAR_SEARCH_HISTORY': {
      return {
        ...state,
        searchSuggest: {
          ...state.searchSuggest,
          history: [],
        },
      };
    }

    default:
      return state;
  }
}
export default headerState;

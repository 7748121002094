const subcategoryMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'MID_GET_SUBCATE_BREADCRUMB': {
      store.dispatch({
        type: 'GET_SUBCATE_BREADCRUMB',
        payload: {
          pc: [
            { name: '居家用品', link: '/' },
            { name: '寢具床墊', link: '/' },
          ],
          mb: [{ name: '居家用品', link: '/' }],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B2': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B2',
        payload: {
          pc_img: 'https://fakeimg.pl/1400x723/?text=Banner_B2',
          mb_img: 'https://fakeimg.pl/428x526/?text=Banner_B2',
          pc_link: '/',
          mb_link: '/',
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B3': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B3',
        payload: {
          pc_img: 'https://fakeimg.pl/1400x723/?text=Banner_B3',
          mb_img: 'https://fakeimg.pl/428x526/?text=Banner_B3',
          pc_link: '/',
          mb_link: '/',
          pc_title: '春夏新品上市',
          mb_title: '春夏新品上市',
          pc_text: 'SHOP THE COLLECTION >',
          mb_text: 'SHOP THE COLLECTION >',
        },
      });
      return next(action);
    }

    case 'MID_GET_SUBCATE_BANNER_B4': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B4',
        payload: {
          pc_title: '春夏新品上市',
          mb_title: '春夏新品上市',
          pc_image: 'https://fakeimg.pl/1400x400/?text=Banner_B4',
          mb_image: 'https://fakeimg.pl/428x660/?text=Banner_B4',
          pc_link: '/',
          mb_link: '/',
          pc_itemTitle: '春夏新品上市',
          mb_itemTitle: '春夏新品上市',
          pc_itemSubtitle:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          mb_itemSubtitle:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          pc_description:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          mb_description:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          pc_materials: {
            id: 'space1',
            link: '/',
            image: 'https://fakeimg.pl/337x101/?text=image_1',
          },
          mb_materials: {
            id: 'space1',
            link: '/',
            image: 'https://fakeimg.pl/320x80/?text=image_1',
          },

          pc_moreLink: '/',
          mb_moreLink: '/',
        },
      });
      return next(action);
    }

    case 'MID_GET_SUBCATE_BANNER_B5': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B5',
        payload: {
          pc_leftImage: 'https://fakeimg.pl/700x794/?text=Banner_B5',
          pc_rightImage: 'https://fakeimg.pl/700x794/a0a0a0?text=Banner_B5',
          mb_rightImage: 'https://fakeimg.pl/428x491/a0a0a0?text=Banner_B5',
          pc_leftImageLink: '/',
          pc_rightImageLink: '/',
          mb_rightImageLink: '/',
          pc_title: '春夏新品上市',
          mb_title: '春夏新品上市',
          pc_subtitle:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          mb_subtitle:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          pc_titleLink: '/',
          mb_titleLink: '/',
          pc_subtitleLink: '/',
          mb_subtitleLink: '/',
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B6': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B6',
        payload: {
          pc_leftVideo:
            'https://s7d5.scene7.com/is/content/Crate/HomePage/2022/02 February/cb_mHP_20220217_DesignDesk_v3.mp4',
          pc_rightImage: 'https://fakeimg.pl/700x794/a0a0a0?text=Banner_B6',
          pc_leftVideoLink: '/',
          pc_rightImageLink: '/',
          pc_leftTitle: '春夏新品上市',
          pc_rightTitle: '春夏新品上市',
          pc_leftLinkText: `SHOP THE COLLECTION >`,
          pc_rightLinkText: 'SHOP THE COLLECTION >',
          mb_leftVideo:
            'https://s7d5.scene7.com/is/content/Crate/HomePage/2022/02 February/cb_mHP_20220217_DesignDesk_v3.mp4',
          mb_rightImage: 'https://fakeimg.pl/428x428/a0a0a0?text=Banner_B6',
          mb_leftVideoLink: '/',
          mb_rightImageLink: '/',
          mb_leftTitle: '春夏新品上市',
          mb_rightTitle: '春夏新品上市',
          mb_leftLinkText: 'SHOP THE COLLECTION >',
          mb_rightLinkText: 'SHOP THE COLLECTION >',
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B7': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B7',
        payload: {
          pc_adTitle: '春夏新品上市',
          pc_adDescription:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          pc_adButtonText: '了解詳情',
          pc_adLink: '/',
          pc_image: 'https://fakeimg.pl/1400x818/?text=Banner_B7',
          pc_imageLink: '/',
          pc_roleTitle: '加配師',
          pc_roleLeftText:
            '送禮送到心坎裡，每份禮物都承載著祝福和心意！想給他/她意想不到的驚喜、獨一無二禮物、還有幸福洋溢的好心情？',
          pc_roleRightText:
            '送禮送到心坎裡，每份禮物都承載著祝福和心意！想給他/她意想不到的驚喜、獨一無二禮物、還有幸福洋溢的好心情？',
          pc_roleImage: 'https://fakeimg.pl/260x260/?text=Banner_B7',
          pc_roleLink: '/',
          mb_adTitle: '春夏新品上市',
          mb_adDescription:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          mb_adButtonText: '了解詳情',
          mb_adLink: '/',
          mb_image: 'https://fakeimg.pl/428x526/?text=Banner_B7',
          mb_imageLink: '/',
          mb_roleTitle: '加配師',
          mb_roleLeftText:
            '送禮送到心坎裡，每份禮物都承載著祝福和心意！想給他/她意想不到的驚喜、獨一無二禮物、還有幸福洋溢的好心情？',
          mb_roleRightText:
            '送禮送到心坎裡，每份禮物都承載著祝福和心意！想給他/她意想不到的驚喜、獨一無二禮物、還有幸福洋溢的好心情？',
          mb_roleImage: 'https://fakeimg.pl/210x210/?text=Banner_B7',
          mb_roleLink: '/',
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B8': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B8',
        payload: {
          pc_title: '春夏新品上市',
          pc_title_link: '/',
          mb_title: '春夏新品上市',
          mb_title_link: '/',
          pc_data: [
            {
              id: 'classification1',
              img: 'https://fakeimg.pl/440x320/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification2',
              img: 'https://fakeimg.pl/440x320/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification3',
              img: 'https://fakeimg.pl/440x320/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification4',
              img: 'https://fakeimg.pl/440x320/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification5',
              img: 'https://fakeimg.pl/440x320/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification6',
              img: 'https://fakeimg.pl/440x320/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
          ],
          mb_data: [
            {
              id: 'classification1',
              img: 'https://fakeimg.pl/204x148/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification2',
              img: 'https://fakeimg.pl/204x148/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification3',
              img: 'https://fakeimg.pl/204x148/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification4',
              img: 'https://fakeimg.pl/204x148/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification5',
              img: 'https://fakeimg.pl/204x148/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
            {
              id: 'classification6',
              img: 'https://fakeimg.pl/204x148/?text=Banner_B8',
              text: '收納',
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B9': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B9',
        payload: {
          pc_title: '春夏新品上市',
          pc_title_link: '/',
          mb_title: '春夏新品上市',
          mb_title_link: '/',
          pc_data: [
            {
              id: 'partnered1',
              img: 'https://fakeimg.pl/440x520/?text=Banner_B9',
              text: '收納',
              link: '/',
            },
            {
              id: 'partnered2',
              img: 'https://fakeimg.pl/440x520/?text=Banner_B9',
              text: '收納',
              link: '/',
            },
            {
              id: 'partnered3',
              img: 'https://fakeimg.pl/440x520/?text=Banner_B9',
              text: '收納',
              link: '/',
            },
          ],
          mb_data: [
            {
              id: 'partnered1',
              img: 'https://fakeimg.pl/428x428/?text=Banner_B9',
              text: '收納',
              link: '/',
            },
            {
              id: 'partnered2',
              img: 'https://fakeimg.pl/428x428/?text=Banner_B9',
              text: '收納',
              link: '/',
            },
            {
              id: 'partnered3',
              img: 'https://fakeimg.pl/428x428/?text=Banner_B9',
              text: '收納',
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B10': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B10',
        payload: {
          pc_title: '春夏新品上市',
          pc_title_link: '/',
          mb_title: '春夏新品上市',
          mb_title_link: '/',
          pc_subtitle:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          pc_subtitle_link: '/',
          mb_subtitle: 'SHOP THE COLLECTION >',
          mb_subtitle_link: '/',
          pc_data: [
            {
              id: 'b10ad1',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B10',
              tag: '@socialmedia-0',
              link: '/',
            },
            {
              id: 'b10ad2',
              img: 'https://fakeimg.pl/467x467/000000?text=Banner_B10',
              tag: '@socialmedia-1',
              link: '/',
            },
            {
              id: 'b10ad3',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B10',
              tag: '@socialmedia-2',
              link: '/',
            },
            {
              id: 'b10ad4',
              img: 'https://fakeimg.pl/467x467/000000?text=Banner_B10',
              tag: '@socialmedia-3',
              link: '/',
            },
            {
              id: 'b10ad5',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B10',
              tag: '@socialmedia-4',
              link: '/',
            },
            {
              id: 'b10ad6',
              img: 'https://fakeimg.pl/467x467/000000?text=Banner_B10',
              tag: '@socialmedia-5',
              link: '/',
            },
            {
              id: 'b10ad7',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B10',
              tag: '@socialmedia-6',
              link: '/',
            },
            {
              id: 'b10ad8',
              img: 'https://fakeimg.pl/467x467/000000?text=Banner_B10',
              tag: '@socialmedia-7',
              link: '/',
            },
            {
              id: 'b10ad9',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B10',
              tag: '@socialmedia-8',
              link: '/',
            },
          ],
          mb_data: [
            {
              id: 'b10ad1',
              img: 'https://fakeimg.pl/328x328/?text=Banner_B10',
              tag: '@socialmedia-0',
              link: '/',
            },
            {
              id: 'b10ad2',
              img: 'https://fakeimg.pl/328x328/000000?text=Banner_B10',
              tag: '@socialmedia-1',
              link: '/',
            },
            {
              id: 'b10ad3',
              img: 'https://fakeimg.pl/328x328/?text=Banner_B10',
              tag: '@socialmedia-2',
              link: '/',
            },
            {
              id: 'b10ad4',
              img: 'https://fakeimg.pl/328x328/000000?text=Banner_B10',
              tag: '@socialmedia-3',
              link: '/',
            },
            {
              id: 'b10ad5',
              img: 'https://fakeimg.pl/328x328/?text=Banner_B10',
              tag: '@socialmedia-4',
              link: '/',
            },
            {
              id: 'b10ad6',
              img: 'https://fakeimg.pl/328x328/000000?text=Banner_B10',
              tag: '@socialmedia-5',
              link: '/',
            },
            {
              id: 'b10ad7',
              img: 'https://fakeimg.pl/328x328/?text=Banner_B10',
              tag: '@socialmedia-6',
              link: '/',
            },
            {
              id: 'b10ad8',
              img: 'https://fakeimg.pl/328x328/000000?text=Banner_B10',
              tag: '@socialmedia-7',
              link: '/',
            },
            {
              id: 'b10ad9',
              img: 'https://fakeimg.pl/328x328/?text=Banner_B10',
              tag: '@socialmedia-8',
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B11': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B11',
        payload: {
          pc_title: '春夏新品上市',
          pc_title_link: '/',
          mb_title: '春夏新品上市',
          mb_title_link: '/',
          pc_subtitle:
            '玩轉空間的牛皮沙發與一隅植景，蘊含綠褐相間的漠外氛圍，形同都市綠洲般的秘境。',
          pc_subtitle_link: '/',
          mb_subtitle: 'SHOP THE COLLECTION >',
          mb_subtitle_link: '/',
          pc_data: [
            {
              id: 'material1',
              img: 'https://fakeimg.pl/467x520/?text=Banner_B11',
              tag: '@socialmedia-0',
              subTag: '@socialm-0',
              link: '/',
            },
            {
              id: 'material2',
              img: 'https://fakeimg.pl/467x520/000000?text=Banner_B11',
              tag: '@socialmedia-1',
              subTag: '@socialm-1',
              link: '/',
            },
            {
              id: 'material3',
              img: 'https://fakeimg.pl/467x520/?text=Banner_B11',
              tag: '@socialmedia-2',
              subTag: '@socialm-2',
              link: '/',
            },
            {
              id: 'material4',
              img: 'https://fakeimg.pl/467x520/000000?text=Banner_B11',
              tag: '@socialmedia-3',
              subTag: '@socialm-3',
              link: '/',
            },
          ],
          mb_data: [
            {
              id: 'material1',
              img: 'https://fakeimg.pl/328x366/?text=Banner_B11',
              tag: '@socialmedia-0',
              subTag: '@socialm-0',
              link: '/',
            },
            {
              id: 'material2',
              img: 'https://fakeimg.pl/328x366/000000?text=Banner_B11',
              tag: '@socialmedia-1',
              subTag: '@socialm-1',
              link: '/',
            },
            {
              id: 'material3',
              img: 'https://fakeimg.pl/328x366/?text=Banner_B11',
              tag: '@socialmedia-2',
              subTag: '@socialm-2',
              link: '/',
            },
            {
              id: 'material4',
              img: 'https://fakeimg.pl/328x366/000000?text=Banner_B11',
              tag: '@socialmedia-3',
              subTag: '@socialm-3',
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B12': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B12',
        payload: {
          pc_images: [
            { id: 'image1', image: 'https://fakeimg.pl/440x100/?text=Banner_B12', link: '/' },
            { id: 'image2', image: 'https://fakeimg.pl/440x100/?text=Banner_B12', link: '/' },
            { id: 'image3', image: 'https://fakeimg.pl/440x100/?text=Banner_B12', link: '/' },
          ],
          mb_images: [
            { id: 'image1', image: 'https://fakeimg.pl/428x100/?text=Banner_B12', link: '/' },
            { id: 'image2', image: 'https://fakeimg.pl/428x100/?text=Banner_B12', link: '/' },
            { id: 'image3', image: 'https://fakeimg.pl/428x100/?text=Banner_B12', link: '/' },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B13': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B13',
        payload: {
          pc_image: 'https://fakeimg.pl/1400x100/?text=Banner_B13',
          mb_image: 'https://fakeimg.pl/428x100/?text=Banner_B13',
          pc_link: '/',
          mb_link: '/',
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B14': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B14',
        payload: {
          pc_image: 'https://fakeimg.pl/680x440/?text=Banner_B14',
          mb_image: 'https://fakeimg.pl/428x277/?text=Banner_B14',
          pc_imageLink: '/',
          mb_imageLink: '/',
          pc_leftProduct: {
            id: 'productId1',
            image: 'https://fakeimg.pl/340x340/?text=Banner_B14',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
          mb_leftProduct: {
            id: 'productId1',
            image: 'https://fakeimg.pl/199x182/?text=Banner_B14',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
          pc_rightProduct: {
            id: 'productId2',
            image: 'https://fakeimg.pl/340x340/?text=Banner_B14',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
          mb_rightProduct: {
            id: 'productId2',
            image: 'https://fakeimg.pl/199x182/?text=Banner_B14',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
        },
      });
      return next(action);
    }

    case 'MID_GET_SUBCATE_BANNER_B15': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B15',
        payload: {
          pc_image: 'https://fakeimg.pl/680x440/?text=Banner_B15',
          pc_imageLink: '/',
          pc_leftProduct: {
            id: 'productId1',
            image: 'https://fakeimg.pl/340x340/?text=Banner_B15',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
          pc_rightProduct: {
            id: 'productId2',
            image: 'https://fakeimg.pl/340x340/?text=Banner_B15',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },

          mb_image: 'https://fakeimg.pl/428x277/?text=Banner_B15',
          mb_imageLink: '/',
          mb_leftProduct: {
            id: 'productId1',
            image: 'https://fakeimg.pl/199x182/?text=Banner_B15',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
          mb_rightProduct: {
            id: 'productId2',
            image: 'https://fakeimg.pl/199x182/?text=Banner_B15',
            title: 'https://fakeimg.pl/120x18/?text=logo',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            isLiked: false,
            link: '/',
          },
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B16': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B16',
        payload: {
          pc_image: 'https://fakeimg.pl/680x880/?text=Banner_B16',
          pc_imageLink: '/',
          pc_products: [
            {
              id: 'productId1',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId2',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId3',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId4',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
          ],

          mb_image: 'https://fakeimg.pl/428x277/?text=Banner_B16',
          mb_imageLink: '/',
          mb_products: [
            {
              id: 'productId1',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId2',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId3',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId4',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B16',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B17': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B17',
        payload: {
          pc_image: 'https://fakeimg.pl/680x880/?text=Banner_B17',
          pc_imageLink: '/',
          pc_products: [
            {
              id: 'productId1',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId2',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId3',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId4',
              image: 'https://fakeimg.pl/340x340/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
          ],

          mb_image: 'https://fakeimg.pl/428x277/?text=Banner_B17',
          mb_imageLink: '/',
          mb_products: [
            {
              id: 'productId1',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId2',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId3',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
            {
              id: 'productId4',
              image: 'https://fakeimg.pl/199x182/?text=Banner_B17',
              title: 'https://fakeimg.pl/120x18/?text=logo',
              name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              price: '$123,456',
              isLiked: false,
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }
    case 'MID_GET_SUBCATE_BANNER_B18': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B18',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          products: Array(4)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=Banner_18',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_SUBCATE_BANNER_B19': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_B19',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          pc_moreText: '點開看該XXX分類全部',
          pc_data: [
            {
              id: 'b19ad1',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad2',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad3',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad4',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad5',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad6',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad7',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad8',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad9',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad10',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad11',
              img: 'https://fakeimg.pl/467x467/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
          ],
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          mb_moreText: '點開看該XXX分類全部',
          mb_data: [
            {
              id: 'b19ad1',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad2',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad3',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad4',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad5',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad6',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad7',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad8',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad9',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad10',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
            {
              id: 'b19ad11',
              img: 'https://fakeimg.pl/150x150/?text=Banner_B19',
              productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
              productPrice: '$123,456',
              link: '/',
            },
          ],
        },
      });
      return next(action);
    }

    case 'MID_GET_NEW_ARRIVAL_CATEGORY': {
      store.dispatch({
        type: 'GET_NEW_ARRIVAL_CATEGORY',
        payload: {
          pc_data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => ({
            id: `new-arrival-category-${index}`,
            img: 'https://fakeimg.pl/520x520/?text=image1',
            text: `New Arrival:\nFurniture >`,
            link: '/',
          })),
          mb_data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => ({
            id: `new-arrival-category-${index}`,
            img: 'https://fakeimg.pl/100x100/?text=image1',
            text: `New Arrival:\nFurniture >`,
            link: '/',
          })),
        },
      });
      return next(action);
    }

    case 'MID_GET_NEW_ARRIVAL_PRODUCT_A1': {
      store.dispatch({
        type: 'GET_NEW_ARRIVAL_PRODUCT_A1',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          pc_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          pc_buttonLink: '/',
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          mb_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          mb_buttonLink: '/',
          products: Array(8)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=newArrival1',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_NEW_ARRIVAL_PRODUCT_A2': {
      store.dispatch({
        type: 'GET_NEW_ARRIVAL_PRODUCT_A2',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          pc_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          pc_buttonLink: '/',
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          mb_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          mb_buttonLink: '/',
          products: Array(8)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=newArrival2',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_NEW_ARRIVAL_PRODUCT_A3': {
      store.dispatch({
        type: 'GET_NEW_ARRIVAL_PRODUCT_A3',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          pc_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          pc_buttonLink: '/',
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          mb_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          mb_buttonLink: '/',
          products: Array(8)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=newArrival3',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_NEW_ARRIVAL_PRODUCT_A4': {
      store.dispatch({
        type: 'GET_NEW_ARRIVAL_PRODUCT_A4',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          pc_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          pc_buttonLink: '/',
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          mb_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          mb_buttonLink: '/',
          products: Array(8)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=newArrival4',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_NEW_ARRIVAL_PRODUCT_A5': {
      store.dispatch({
        type: 'GET_NEW_ARRIVAL_PRODUCT_A5',
        payload: {
          pc_title: '春夏新品上市',
          pc_moreLink: '/',
          pc_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          pc_buttonLink: '/',
          mb_title: '春夏新品上市',
          mb_moreLink: '/',
          mb_buttonText: 'SHOP ALL NEW ARRIVALS: FURNITURE (216)',
          mb_buttonLink: '/',
          products: Array(8)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=newArrival5',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_SUGGEST_CATEGORY': {
      store.dispatch({
        type: 'GET_SUGGEST_CATEGORY',
        payload: {
          pc_title: '選擇分類',
          pc_data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => ({
            id: `suggest-category-${index}`,
            img: 'https://fakeimg.pl/520x520/?text=Recent1',
            text: `New Arrival:\nFurniture >`,
            link: '/',
          })),
          mb_title: '選擇分類',
          mb_data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => ({
            id: `suggest-category-${index}`,
            img: 'https://fakeimg.pl/100x100/?text=Recent1',
            text: `New Arrival:\nFurniture >`,
            link: '/',
          })),
        },
      });
      return next(action);
    }

    case 'MID_GET_SUBCATE_BANNER_RECENT': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_RECENT',
        payload: {
          pc_title: '最近瀏覽過的商品',
          mb_title: '最近瀏覽過的商品',
          products: Array(11)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=recent',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_SUBCATE_BANNER_RELATED': {
      store.dispatch({
        type: 'GET_SUBCATE_BANNER_RELATED',
        payload: {
          pc_title: '相關分類推薦',
          pc_data: [
            { id: 'relatedCategory1', name: 'Contemporary Furniture', link: '/' },
            { id: 'relatedCategory2', name: 'Upholstered Furniture', link: '/' },
            { id: 'relatedCategory3', name: 'Recliners', link: '/' },
            { id: 'relatedCategory4', name: 'Reclaimed Wood Tables', link: '/' },
            { id: 'relatedCategory5', name: 'Sofa Slipcovers', link: '/' },
            { id: 'relatedCategory6', name: 'Wicker Furniture', link: '/' },
          ],
          mb_title: '相關分類推薦',
          mb_data: [
            { id: 'relatedCategory1', name: 'Contemporary Furniture', link: '/' },
            { id: 'relatedCategory2', name: 'Upholstered Furniture', link: '/' },
            { id: 'relatedCategory3', name: 'Recliners', link: '/' },
            { id: 'relatedCategory4', name: 'Reclaimed Wood Tables', link: '/' },
            { id: 'relatedCategory5', name: 'Sofa Slipcovers', link: '/' },
            { id: 'relatedCategory6', name: 'Wicker Furniture', link: '/' },
          ],
        },
      });
      return next(action);
    }
    default:
      return next(action);
  }
};

export default subcategoryMiddleware;

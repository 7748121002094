const initState = {
  searchResult: { keyword: '', count: 0 },
  productFilter: {
    filter: [],
    sorter: [],
    result: [],
    products: [],
  },
  bannerRecent: {},
  bannerRelated: {},
};

export function searchState(state = initState, action) {
  switch (action.type) {
    case 'GET_SEARCH_RESULT': {
      return {
        ...state,
        searchResult: action.payload,
      };
    }

    case 'GET_SEARCH_PRODUCT_FILTER': {
      return {
        ...state,
        productFilter: action.payload,
      };
    }

    case 'UPDATE_SEARCH_PRODUCT_FILTER': {
      const { index, data } = action.payload;
      const productArray = state.productFilter.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        productFilter: { ...state.productFilter, products: productArray },
      };
    }

    case 'GET_SEARCH_BANNER_RECENT': {
      return {
        ...state,
        bannerRecent: action.payload,
      };
    }

    case 'UPDATE_SEARCH_BANNER_RECENT': {
      const { index, data } = action.payload;
      const productArray = state.bannerRecent.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        bannerRecent: { ...state.bannerRecent, products: productArray },
      };
    }

    case 'GET_SEARCH_BANNER_RELATED': {
      return {
        ...state,
        bannerRelated: action.payload,
      };
    }

    default:
      return state;
  }
}
export default searchState;

const initState = {
  breadcrumb: {},
  childCateBannerB2: {},
  childCateBannerB3: {},
  childCateBannerB4: {},
  childCateBannerB5: {},
  childCateBannerB6: {},
  childCateBannerB7: {},
  childCateBannerB8: {},
  childCateBannerB9: {},
  childCateBannerB10: {},
  childCateBannerB11: {},
  childCateBannerB12: {},
  childCateBannerB13: {},
  childCateBannerB14: {},
  childCateBannerB15: {},
  childCateBannerB16: {},
  childCateBannerB17: {},
  childCateBannerB18: {},
  childCateBannerB19: {},
  childCateSuggestCategory: {},
  newArrivalProduct: {},
  childCateProductFilter: {
    filter: [],
    sorter: [],
    result: [],
    products: [],
  },
  childCateBannerRecent: {},
  childCateBannerRelated: {},
};

export function childCategoryState(state = initState, action) {
  switch (action.type) {
    case 'GET_CHILD_CATE_BREADCRUMB': {
      return {
        ...state,
        breadcrumb: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B2': {
      return {
        ...state,
        childCateBannerB2: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B3': {
      return {
        ...state,
        childCateBannerB3: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B4': {
      return {
        ...state,
        childCateBannerB4: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B5': {
      return {
        ...state,
        childCateBannerB5: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B6': {
      return {
        ...state,
        childCateBannerB6: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B7': {
      return {
        ...state,
        childCateBannerB7: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B8': {
      return {
        ...state,
        childCateBannerB8: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B9': {
      return {
        ...state,
        childCateBannerB9: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B10': {
      return {
        ...state,
        childCateBannerB10: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B11': {
      return {
        ...state,
        childCateBannerB11: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B12': {
      return {
        ...state,
        childCateBannerB12: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B13': {
      return {
        ...state,
        childCateBannerB13: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B14': {
      return {
        ...state,
        childCateBannerB14: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B15': {
      return {
        ...state,
        childCateBannerB15: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B16': {
      return {
        ...state,
        childCateBannerB16: action.payload,
      };
    }
    case 'GET_CHILD_CATE_BANNER_B17': {
      return {
        ...state,
        childCateBannerB17: action.payload,
      };
    }

    case 'GET_CHILD_CATE_BANNER_B18': {
      return {
        ...state,
        childCateBannerB18: action.payload,
      };
    }

    case 'UPDATE_CHILD_CATE_BANNER_B18': {
      const { device, index, data } = action.payload;
      const productArray = state.childCateBannerB18.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        childCateBannerB18: { ...state.childCateBannerB18, products: productArray },
      };
    }

    case 'GET_CHILD_CATE_BANNER_B19': {
      return {
        ...state,
        childCateBannerB19: action.payload,
      };
    }

    case 'GET_CHILD_CATE_SUGGEST_CATEGORY': {
      return {
        ...state,
        childCateSuggestCategory: action.payload,
      };
    }

    case 'GET_CHILD_CATE_NEW_ARRIVAL_PRODUCT': {
      return {
        ...state,
        newArrivalProduct: action.payload,
      };
    }

    case 'GET_CHILD_CATE_PRODUCT_FILTER': {
      return {
        ...state,
        childCateProductFilter: action.payload,
      };
    }

    case 'UPDATE_CHILD_CATE_PRODUCT_FILTER': {
      const { index, data } = action.payload;
      const productArray = state.childCateProductFilter.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        childCateProductFilter: { ...state.childCateProductFilter, products: productArray },
      };
    }

    case 'GET_CHILD_CATE_BANNER_RECENT': {
      return {
        ...state,
        childCateBannerRecent: action.payload,
      };
    }

    case 'UPDATE_CHILD_CATE_BANNER_RECENT': {
      const { index, data } = action.payload;
      const productArray = state.childCateBannerRecent.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        childCateBannerRecent: { ...state.childCateBannerRecent, products: productArray },
      };
    }

    case 'GET_CHILD_CATE_BANNER_RELATED': {
      return {
        ...state,
        childCateBannerRelated: action.payload,
      };
    }

    default:
      return state;
  }
}
export default childCategoryState;

const searchMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'MID_GET_SEARCH_RESULT': {
      const keyword = action.payload.keyword;
      // 為了呈現查無結果的頁面，目前查詢chair關鍵字時會查無結果
      store.dispatch({
        type: 'GET_SEARCH_RESULT',
        payload: {
          pc_keyword: keyword,
          pc_count: keyword === 'chair' ? 0 : 123,
          mb_keyword: keyword,
          mb_count: keyword === 'chair' ? 0 : 123,
        },
      });
      return next(action);
    }

    case 'MID_GET_SEARCH_PRODUCT_FILTER': {
      store.dispatch({
        type: 'GET_SEARCH_PRODUCT_FILTER',
        payload: {
          filter: [
            {
              id: 'filter-1',
              title: '廚房用具',
              moreAmount: 3,
              moreLink: '/',
              items: [
                {
                  id: 'filter-1-1',
                  title: '鍋',
                  amount: 12,
                },
                {
                  id: 'filter-1-2',
                  title: '碗',
                  amount: 12,
                },
                {
                  id: 'filter-1-3',
                  title: '湯匙',
                  amount: 12,
                },
                {
                  id: 'filter-1-4',
                  title: '盆',
                  amount: 12,
                },
                {
                  id: 'filter-1-5',
                  title: '鑄鐵鍋',
                  amount: 12,
                },
                {
                  id: 'filter-1-6',
                  title: '鑄鐵鍋',
                  amount: 12,
                },
                {
                  id: 'filter-1-7',
                  title: '鑄鐵鍋',
                  amount: 12,
                },
              ],
            },
            {
              id: 'filter-2',
              title: 'filter-2',
              moreAmount: 6,
              moreLink: '/',
              items: [
                {
                  id: 'filter-2-1',
                  title: 'filter-2-1',
                  amount: 12,
                },
                {
                  id: 'filter-2-2',
                  title: 'filter-2-2',
                  amount: 12,
                },
                {
                  id: 'filter-2-3',
                  title: 'filter-2-3',
                  amount: 12,
                },
                {
                  id: 'filter-2-4',
                  title: 'filter-2-4',
                  amount: 12,
                },
              ],
            },
            {
              id: 'filter-3',
              title: 'filter-3',
              moreAmount: 3,
              moreLink: '/',
              items: [
                {
                  id: 'filter-3-1',
                  title: 'filter-3-1',
                  amount: 12,
                },
                {
                  id: 'filter-3-2',
                  title: 'filter-3-2',
                  amount: 12,
                },
                {
                  id: 'filter-3-3',
                  title: 'filter-3-3',
                  amount: 12,
                },
                {
                  id: 'filter-3-4',
                  title: 'filter-3-4',
                  amount: 12,
                },
                {
                  id: 'filter-3-5',
                  title: 'filter-3-5',
                  amount: 12,
                },
                {
                  id: 'filter-3-6',
                  title: 'filter-3-6',
                  amount: 12,
                },
                {
                  id: 'filter-3-7',
                  title: 'filter-3-7',
                  amount: 12,
                },
                {
                  id: 'filter-3-8',
                  title: 'filter-3-8',
                  amount: 12,
                },
              ],
            },
            {
              id: 'filter-4',
              title: 'filter-4',
              moreAmount: 3,
              moreLink: '/',
              items: [
                {
                  id: 'filter-4-1',
                  title: 'filter-4-1',
                  amount: 12,
                },
                {
                  id: 'filter-4-2',
                  title: 'filter-4-2',
                  amount: 12,
                },
                {
                  id: 'filter-4-3',
                  title: 'filter-4-3',
                  amount: 12,
                },
                {
                  id: 'filter-4-4',
                  title: 'filter-4-4',
                  amount: 12,
                },
                {
                  id: 'filter-4-5',
                  title: 'filter-4-5',
                  amount: 12,
                },
                {
                  id: 'filter-4-6',
                  title: 'filter-4-6',
                  amount: 12,
                },
                {
                  id: 'filter-4-7',
                  title: 'filter-4-7',
                  amount: 12,
                },
                {
                  id: 'filter-4-8',
                  title: 'filter-4-8',
                  amount: 12,
                },
              ],
            },
            {
              id: 'filter-5',
              title: 'filter-5',
              moreAmount: 3,
              moreLink: '/',
              items: [
                {
                  id: 'filter-5-1',
                  title: 'filter-5-1',
                  amount: 12,
                },
                {
                  id: 'filter-5-2',
                  title: 'filter-5-2',
                  amount: 12,
                },
                {
                  id: 'filter-5-3',
                  title: 'filter-5-3',
                  amount: 12,
                },
                {
                  id: 'filter-5-4',
                  title: 'filter-5-4',
                  amount: 12,
                },
                {
                  id: 'filter-5-5',
                  title: 'filter-5-5',
                  amount: 12,
                },
                {
                  id: 'filter-5-6',
                  title: 'filter-5-6',
                  amount: 12,
                },
                {
                  id: 'filter-5-7',
                  title: 'filter-5-7',
                  amount: 12,
                },
                {
                  id: 'filter-5-8',
                  title: 'filter-5-8',
                  amount: 12,
                },
              ],
            },
            {
              id: 'filter-6',
              title: 'filter-6',
              moreAmount: 3,
              moreLink: '/',
              items: [
                {
                  id: 'filter-6-1',
                  title: 'filter-6-1',
                  amount: 12,
                },
                {
                  id: 'filter-6-2',
                  title: 'filter-6-2',
                  amount: 12,
                },
                {
                  id: 'filter-6-3',
                  title: 'filter-6-3',
                  amount: 12,
                },
                {
                  id: 'filter-6-4',
                  title: 'filter-6-4',
                  amount: 12,
                },
                {
                  id: 'filter-6-5',
                  title: 'filter-6-5',
                  amount: 12,
                },
                {
                  id: 'filter-6-6',
                  title: 'filter-6-6',
                  amount: 12,
                },
                {
                  id: 'filter-6-7',
                  title: 'filter-6-7',
                  amount: 12,
                },
                {
                  id: 'filter-6-8',
                  title: 'filter-6-8',
                  amount: 12,
                },
              ],
            },
            {
              id: 'filter-7',
              title: 'filter-7',
              moreAmount: 3,
              moreLink: '/',
              items: [
                {
                  id: 'filter-7-1',
                  title: 'filter-7-1',
                  amount: 12,
                },
                {
                  id: 'filter-7-2',
                  title: 'filter-7-2',
                  amount: 12,
                },
                {
                  id: 'filter-7-3',
                  title: 'filter-7-3',
                  amount: 12,
                },
                {
                  id: 'filter-7-4',
                  title: 'filter-7-4',
                  amount: 12,
                },
                {
                  id: 'filter-7-5',
                  title: 'filter-7-5',
                  amount: 12,
                },
                {
                  id: 'filter-7-6',
                  title: 'filter-7-6',
                  amount: 12,
                },
                {
                  id: 'filter-7-7',
                  title: 'filter-7-7',
                  amount: 12,
                },
                {
                  id: 'filter-7-8',
                  title: 'filter-7-8',
                  amount: 12,
                },
              ],
            },
          ],
          sorter: [
            {
              id: 'sorter-1',
              title: '最相關',
            },
            {
              id: 'sorter-2',
              title: '熱銷',
            },
            {
              id: 'sorter-3',
              title: '新品',
            },
            {
              id: 'sorter-4',
              title: '熱搜',
            },
            {
              id: 'sorter-5',
              title: '價格高到低',
            },
            {
              id: 'sorter-6',
              title: '價格低到高',
            },
          ],
          products: Array(35)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=image',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
          pc_buttonText: '看更多商品 (100 / 250)',
          pc_buttonLink: '/',
          mb_buttonText: '看更多商品 (100 / 250)',
          mb_buttonLink: '/',
        },
      });
      return next(action);
    }

    case 'MID_GET_SEARCH_BANNER_RECENT': {
      store.dispatch({
        type: 'GET_SEARCH_BANNER_RECENT',
        payload: {
          pc_title: '最近瀏覽過的商品',
          mb_title: '最近瀏覽過的商品',
          products: Array(11)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=recent',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_SEARCH_BANNER_RELATED': {
      store.dispatch({
        type: 'GET_SEARCH_BANNER_RELATED',
        payload: {
          pc_title: '相關分類推薦',
          pc_data: [
            { id: 'relatedCategory1', name: 'Contemporary Furniture', link: '/' },
            { id: 'relatedCategory2', name: 'Upholstered Furniture', link: '/' },
            { id: 'relatedCategory3', name: 'Recliners', link: '/' },
            { id: 'relatedCategory4', name: 'Reclaimed Wood Tables', link: '/' },
            { id: 'relatedCategory5', name: 'Sofa Slipcovers', link: '/' },
            { id: 'relatedCategory6', name: 'Wicker Furniture', link: '/' },
          ],
          mb_title: '相關分類推薦',
          mb_data: [
            { id: 'relatedCategory1', name: 'Contemporary Furniture', link: '/' },
            { id: 'relatedCategory2', name: 'Upholstered Furniture', link: '/' },
            { id: 'relatedCategory3', name: 'Recliners', link: '/' },
            { id: 'relatedCategory4', name: 'Reclaimed Wood Tables', link: '/' },
            { id: 'relatedCategory5', name: 'Sofa Slipcovers', link: '/' },
            { id: 'relatedCategory6', name: 'Wicker Furniture', link: '/' },
          ],
        },
      });
      return next(action);
    }

    default:
      return next(action);
  }
};

export default searchMiddleware;

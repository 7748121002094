const initState = {
  breadcrumb: {},
  subcateBannerB2: {},
  subcateBannerB3: {},
  subcateBannerB4: {},
  subcateBannerB5: {},
  subcateBannerB6: {},
  subcateBannerB7: {},
  subcateBannerB8: {},
  subcateBannerB9: {},
  subcateBannerB10: {},
  subcateBannerB11: {},
  subcateBannerB12: {},
  subcateBannerB13: {},
  subcateBannerB14: {},
  subcateBannerB15: {},
  subcateBannerB16: {},
  subcateBannerB17: {},
  subcateBannerB18: {},
  subcateBannerB19: {},
  newArrivalCategory: {},
  newArrivalProductA1: {},
  newArrivalProductA2: {},
  newArrivalProductA3: {},
  newArrivalProductA4: {},
  newArrivalProductA5: {},
  suggestCategory: {},
  subcateBannerRecent: {},
  subcateBannerRelated: {},
};

export function subcategoryState(state = initState, action) {
  switch (action.type) {
    case 'GET_SUBCATE_BREADCRUMB': {
      return {
        ...state,
        breadcrumb: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B2': {
      return {
        ...state,
        subcateBannerB2: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B3': {
      return {
        ...state,
        subcateBannerB3: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B4': {
      return {
        ...state,
        subcateBannerB4: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B5': {
      return {
        ...state,
        subcateBannerB5: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B6': {
      return {
        ...state,
        subcateBannerB6: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B7': {
      return {
        ...state,
        subcateBannerB7: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B8': {
      return {
        ...state,
        subcateBannerB8: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B9': {
      return {
        ...state,
        subcateBannerB9: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B10': {
      return {
        ...state,
        subcateBannerB10: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B11': {
      return {
        ...state,
        subcateBannerB11: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B12': {
      return {
        ...state,
        subcateBannerB12: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B13': {
      return {
        ...state,
        subcateBannerB13: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B14': {
      return {
        ...state,
        subcateBannerB14: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B15': {
      return {
        ...state,
        subcateBannerB15: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B16': {
      return {
        ...state,
        subcateBannerB16: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B17': {
      return {
        ...state,
        subcateBannerB17: action.payload,
      };
    }
    case 'GET_SUBCATE_BANNER_B18': {
      return {
        ...state,
        subcateBannerB18: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_BANNER_B18': {
      const { index, data } = action.payload;
      const productArray = state.subcateBannerB18.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        subcateBannerB18: { ...state.subcateBannerB18, products: productArray },
      };
    }

    case 'GET_SUBCATE_BANNER_B19': {
      return {
        ...state,
        subcateBannerB19: action.payload,
      };
    }

    case 'GET_NEW_ARRIVAL_CATEGORY': {
      return {
        ...state,
        newArrivalCategory: action.payload,
      };
    }

    case 'GET_NEW_ARRIVAL_PRODUCT_A1': {
      return {
        ...state,
        newArrivalProductA1: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_NEW_ARRIVAL_PRODUCT_A1': {
      const { index, data } = action.payload;
      const productArray = state.newArrivalProductA1.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        newArrivalProductA1: { ...state.newArrivalProductA1, products: productArray },
      };
    }

    case 'GET_NEW_ARRIVAL_PRODUCT_A2': {
      return {
        ...state,
        newArrivalProductA2: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_NEW_ARRIVAL_PRODUCT_A2': {
      const { index, data } = action.payload;
      const productArray = state.newArrivalProductA2.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        newArrivalProductA2: { ...state.newArrivalProductA2, products: productArray },
      };
    }

    case 'GET_NEW_ARRIVAL_PRODUCT_A3': {
      return {
        ...state,
        newArrivalProductA3: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_NEW_ARRIVAL_PRODUCT_A3': {
      const { index, data } = action.payload;
      const productArray = state.newArrivalProductA3.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        newArrivalProductA3: { ...state.newArrivalProductA3, products: productArray },
      };
    }

    case 'GET_NEW_ARRIVAL_PRODUCT_A4': {
      return {
        ...state,
        newArrivalProductA4: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_NEW_ARRIVAL_PRODUCT_A4': {
      const { index, data } = action.payload;
      const productArray = state.newArrivalProductA4.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        newArrivalProductA4: { ...state.newArrivalProductA4, products: productArray },
      };
    }

    case 'GET_NEW_ARRIVAL_PRODUCT_A5': {
      return {
        ...state,
        newArrivalProductA5: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_NEW_ARRIVAL_PRODUCT_A5': {
      const { index, data } = action.payload;
      const productArray = state.newArrivalProductA5.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        newArrivalProductA5: { ...state.newArrivalProductA5, products: productArray },
      };
    }

    case 'GET_SUGGEST_CATEGORY': {
      return {
        ...state,
        suggestCategory: action.payload,
      };
    }

    case 'GET_SUBCATE_BANNER_RECENT': {
      return {
        ...state,
        subcateBannerRecent: action.payload,
      };
    }

    case 'UPDATE_SUBCATE_BANNER_RECENT': {
      const { index, data } = action.payload;
      const productArray = state.subcateBannerRecent.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        subcateBannerRecent: { ...state.subcateBannerRecent, products: productArray },
      };
    }

    case 'GET_SUBCATE_BANNER_RELATED': {
      return {
        ...state,
        subcateBannerRelated: action.payload,
      };
    }

    default:
      return state;
  }
}
export default subcategoryState;

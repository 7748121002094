const headerMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'MID_GET_HOLA_BANNER_A2': {
      store.dispatch({
        type: 'GET_HOLA_BANNER_A2',
        payload: [
          { id: 'banner-1', name: '家的企劃師', link: '/', cover: {} },
          { id: 'banner-2', name: '職人專訪', link: '/', cover: {} },
          { id: 'banner-3', name: '跨界聯名', link: '/', cover: {} },
          {
            id: 'banner-4',
            name: '家的提案',
            link: '/',
            cover: {
              categories: [
                {
                  title: 'Type something',
                  items: [
                    { title: 'Type something', link: '/' },
                    { title: 'Type something', link: '/' },
                    { title: 'Type something', link: '/' },
                    { title: 'Type something', link: '/' },
                    { title: 'Type something', link: '/' },
                  ],
                },
                {
                  title: 'Type something',
                  items: [
                    { title: 'Type something', link: '/' },
                    { title: 'Type something', link: '/' },
                  ],
                },
              ],
              focus: [
                { title: 'Type something', link: '/' },
                { title: 'Type something', link: '/' },
                { title: 'Type something', link: '/' },
              ],
              preview: {
                img: 'https://fakeimg.pl/357x243/?text=image_1',
                text: 'Type something',
                link: '/',
              },
            },
          },
          { id: 'banner-5', name: '新品', link: '/', cover: {} },
          { id: 'banner-6', name: '情人節', link: '/', cover: {} },
          { id: 'banner-6', name: '關於HOLA', link: '/', cover: {} },
        ],
      });
      return next(action);
    }

    case 'MID_GET_HOLA_BANNER_A3': {
      store.dispatch({
        type: 'GET_HOLA_BANNER_A3',
        payload: [
          {
            id: 'cate-00',
            title: '獨家系列',
            content: {
              titleLeft: 'type something-00-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-00',
              linkLeft: '/',
              titleRight: 'type something-00-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-00',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-00',
              img: 'https://fakeimg.pl/355x355/?text=cate-00',
              link: '/',
            },
            link: '/category/cate-00',
            child: [
              {
                id: 'cate-00-00',
                title: 'cate-00-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-00-00-00',
                    title: 'cate-00-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-01',
                    title: 'cate-00-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-02',
                    title: 'cate-00-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-03',
                    title: 'cate-00-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-04',
                    title: 'cate-00-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-05',
                    title: 'cate-00-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-06',
                    title: 'cate-00-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-07',
                    title: 'cate-00-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-08',
                    title: 'cate-00-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-00-09',
                    title: 'cate-00-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-00-01',
                title: 'cate-00-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-00-01-00',
                    title: 'cate-00-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-01',
                    title: 'cate-00-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-02',
                    title: 'cate-00-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-03',
                    title: 'cate-00-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-04',
                    title: 'cate-00-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-05',
                    title: 'cate-00-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-06',
                    title: 'cate-00-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-07',
                    title: 'cate-00-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-08',
                    title: 'cate-00-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-01-09',
                    title: 'cate-00-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-00-02',
                title: 'cate-00-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-00-03',
                title: 'cate-00-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-00-04',
                title: 'cate-00-04 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-00-04-00',
                    title: 'cate-00-04-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-04-01',
                    title: 'cate-00-04-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-04-02',
                    title: 'cate-00-04-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-00-04-03',
                    title: 'cate-00-04-03 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-00-05',
                title: 'cate-00-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-00-06',
                title: 'cate-00-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-00-07',
                title: 'cate-00-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-00-08',
                title: 'cate-00-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-01',
            title: '寢具床墊',
            content: {
              titleLeft: 'type something-01-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-01',
              linkLeft: '/',
              titleRight: 'type something-01-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-01',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-01',
              img: 'https://fakeimg.pl/355x355/?text=cate-01',
              link: '/',
            },
            link: '/category/cate-01',
            child: [
              {
                id: 'cate-01-00',
                title: 'cate-01-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-01-00-00',
                    title: 'cate-01-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-01',
                    title: 'cate-01-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-02',
                    title: 'cate-01-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-03',
                    title: 'cate-01-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-04',
                    title: 'cate-01-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-05',
                    title: 'cate-01-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-06',
                    title: 'cate-01-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-07',
                    title: 'cate-01-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-08',
                    title: 'cate-01-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-00-09',
                    title: 'cate-01-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-01-01',
                title: 'cate-01-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-01-01-00',
                    title: 'cate-01-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-01',
                    title: 'cate-01-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-02',
                    title: 'cate-01-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-03',
                    title: 'cate-01-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-04',
                    title: 'cate-01-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-05',
                    title: 'cate-01-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-06',
                    title: 'cate-01-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-07',
                    title: 'cate-01-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-08',
                    title: 'cate-01-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-01-01-09',
                    title: 'cate-01-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-01-02',
                title: 'cate-01-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-01-03',
                title: 'cate-01-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-01-04',
                title: 'cate-01-04 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-01-05',
                title: 'cate-01-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-01-06',
                title: 'cate-01-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-01-07',
                title: 'cate-01-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-01-08',
                title: 'cate-01-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-02',
            title: '香氛衛浴',
            content: {
              titleLeft: 'type something-02-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-02',
              linkLeft: '/',
              titleRight: 'type something-02-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-02',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-02',
              img: 'https://fakeimg.pl/355x355/?text=cate-02',
              link: '/',
            },
            link: '/category/cate-02',
            child: [
              {
                id: 'cate-02-00',
                title: 'cate-02-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-02-00-00',
                    title: 'cate-02-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-01',
                    title: 'cate-02-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-02',
                    title: 'cate-02-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-03',
                    title: 'cate-02-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-04',
                    title: 'cate-02-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-05',
                    title: 'cate-02-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-06',
                    title: 'cate-02-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-07',
                    title: 'cate-02-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-08',
                    title: 'cate-02-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-00-09',
                    title: 'cate-02-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-02-01',
                title: 'cate-02-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-02-01-00',
                    title: 'cate-02-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-01',
                    title: 'cate-02-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-02',
                    title: 'cate-02-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-03',
                    title: 'cate-02-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-04',
                    title: 'cate-02-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-05',
                    title: 'cate-02-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-06',
                    title: 'cate-02-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-07',
                    title: 'cate-02-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-08',
                    title: 'cate-02-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-02-01-09',
                    title: 'cate-02-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-02-02',
                title: 'cate-02-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-02-03',
                title: 'cate-02-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-02-04',
                title: 'cate-02-04 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-02-05',
                title: 'cate-02-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-02-06',
                title: 'cate-02-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-02-07',
                title: 'cate-02-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-02-08',
                title: 'cate-02-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-03',
            title: '傢俱收納',
            content: {
              titleLeft: 'type something-03-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-03',
              linkLeft: '/',
              titleRight: 'type something-03-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-03',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-03',
              img: 'https://fakeimg.pl/355x355/?text=cate-03',
              link: '/',
            },
            link: '/category/cate-03',
            child: [
              {
                id: 'cate-03-00',
                title: 'cate-03-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-03-00-00',
                    title: 'cate-03-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-01',
                    title: 'cate-03-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-02',
                    title: 'cate-03-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-03',
                    title: 'cate-03-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-04',
                    title: 'cate-03-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-05',
                    title: 'cate-03-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-06',
                    title: 'cate-03-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-07',
                    title: 'cate-03-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-08',
                    title: 'cate-03-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-00-09',
                    title: 'cate-03-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-03-01',
                title: 'cate-03-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-03-01-00',
                    title: 'cate-03-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-01',
                    title: 'cate-03-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-02',
                    title: 'cate-03-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-03',
                    title: 'cate-03-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-04',
                    title: 'cate-03-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-05',
                    title: 'cate-03-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-06',
                    title: 'cate-03-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-07',
                    title: 'cate-03-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-08',
                    title: 'cate-03-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-03-01-09',
                    title: 'cate-03-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-03-02',
                title: 'cate-03-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-03-03',
                title: 'cate-03-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-03-04',
                title: 'cate-03-04 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-03-05',
                title: 'cate-03-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-03-06',
                title: 'cate-03-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-03-07',
                title: 'cate-03-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-03-08',
                title: 'cate-03-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-04',
            title: '家飾紡品',
            content: {
              titleLeft: 'type something-04-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-04',
              linkLeft: '/',
              titleRight: 'type something-04-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-04',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-04',
              img: 'https://fakeimg.pl/355x355/?text=cate-04',
              link: '/',
            },
            link: '/category/cate-04',
            child: [
              {
                id: 'cate-04-00',
                title: 'cate-04-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-04-00-00',
                    title: 'cate-04-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-01',
                    title: 'cate-04-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-02',
                    title: 'cate-04-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-03',
                    title: 'cate-04-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-04',
                    title: 'cate-04-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-05',
                    title: 'cate-04-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-06',
                    title: 'cate-04-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-07',
                    title: 'cate-04-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-08',
                    title: 'cate-04-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-00-09',
                    title: 'cate-04-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-04-01',
                title: 'cate-04-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-04-01-00',
                    title: 'cate-04-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-01',
                    title: 'cate-04-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-02',
                    title: 'cate-04-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-03',
                    title: 'cate-04-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-04',
                    title: 'cate-04-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-05',
                    title: 'cate-04-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-06',
                    title: 'cate-04-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-07',
                    title: 'cate-04-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-08',
                    title: 'cate-04-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-04-01-09',
                    title: 'cate-04-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-04-02',
                title: 'cate-04-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-04-03',
                title: 'cate-04-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-04-04',
                title: 'cate-04-04 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-04-05',
                title: 'cate-04-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-04-06',
                title: 'cate-04-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-04-07',
                title: 'cate-04-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-04-08',
                title: 'cate-04-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-05',
            title: '餐廚用品',
            content: {
              titleLeft: 'type something-05-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-05',
              linkLeft: '/',
              titleRight: 'type something-05-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-05',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-05',
              img: 'https://fakeimg.pl/355x355/?text=cate-05',
              link: '/',
            },
            link: '/category/cate-05',
            child: [
              {
                id: 'cate-05-00',
                title: 'cate-05-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-05-00-00',
                    title: 'cate-05-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-01',
                    title: 'cate-05-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-02',
                    title: 'cate-05-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-03',
                    title: 'cate-05-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-04',
                    title: 'cate-05-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-05',
                    title: 'cate-05-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-06',
                    title: 'cate-05-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-07',
                    title: 'cate-05-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-08',
                    title: 'cate-05-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-00-09',
                    title: 'cate-05-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-05-01',
                title: 'cate-05-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-05-01-00',
                    title: 'cate-05-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-01',
                    title: 'cate-05-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-02',
                    title: 'cate-05-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-03',
                    title: 'cate-05-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-04',
                    title: 'cate-05-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-05',
                    title: 'cate-05-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-06',
                    title: 'cate-05-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-07',
                    title: 'cate-05-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-08',
                    title: 'cate-05-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-05-01-09',
                    title: 'cate-05-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-05-02',
                title: 'cate-05-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-05-03',
                title: 'cate-05-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-05-04',
                title: 'cate-05-04 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-05-05',
                title: 'cate-05-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-05-06',
                title: 'cate-05-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-05-07',
                title: 'cate-05-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-05-08',
                title: 'cate-05-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-06',
            title: '美食飲品',
            content: {
              titleLeft: 'type something-06-1',
              imgLeft: 'https://fakeimg.pl/367x500/?text=cate-06',
              linkLeft: '/',
              titleRight: 'type something-06-2',
              imgRight: 'https://fakeimg.pl/733x500/?text=cate-06',
              linkRight: '/',
            },
            contentMB: {
              title: 'type something-06',
              img: 'https://fakeimg.pl/355x355/?text=cate-06',
              link: '/',
            },
            link: '/category/cate-06',
            child: [
              {
                id: 'cate-06-00',
                title: 'cate-06-00 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-06-00-00',
                    title: 'cate-06-00-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-01',
                    title: 'cate-06-00-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-02',
                    title: 'cate-06-00-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-03',
                    title: 'cate-06-00-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-04',
                    title: 'cate-06-00-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-05',
                    title: 'cate-06-00-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-06',
                    title: 'cate-06-00-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-07',
                    title: 'cate-06-00-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-08',
                    title: 'cate-06-00-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-00-09',
                    title: 'cate-06-00-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-06-01',
                title: 'cate-06-01 type something',
                link: '/',
                child: [
                  {
                    id: 'cate-06-01-00',
                    title: 'cate-06-01-00 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-01',
                    title: 'cate-06-01-01 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-02',
                    title: 'cate-06-01-02 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-03',
                    title: 'cate-06-01-03 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-04',
                    title: 'cate-06-01-04 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-05',
                    title: 'cate-06-01-05 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-06',
                    title: 'cate-06-01-06 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-07',
                    title: 'cate-06-01-07 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-08',
                    title: 'cate-06-01-08 type something',
                    link: '/',
                  },
                  {
                    id: 'cate-06-01-09',
                    title: 'cate-06-01-09 type something',
                    link: '/',
                  },
                ],
              },
              {
                id: 'cate-06-02',
                title: 'cate-06-02 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-06-03',
                title: 'cate-06-03 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-06-04',
                title: 'cate-06-04 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-06-05',
                title: 'cate-06-05 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-06-06',
                title: 'cate-06-06 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-06-07',
                title: 'cate-06-07 type something',
                link: '/',
                child: [],
              },
              {
                id: 'cate-06-08',
                title: 'cate-06-08 type something',
                link: '/',
                child: [],
              },
            ],
          },
          {
            id: 'cate-07',
            title: '家電淨水',
            link: '/category/cate-07',
            child: [],
          },
          {
            id: 'cate-08',
            title: '家用清潔',
            link: '/category/cate-08',
            child: [],
          },
          {
            id: 'cate-09',
            title: '運動休閒',
            link: '/category/cate-09',
            child: [],
          },
          {
            id: 'cate-10',
            title: '兒童用品',
            link: '/category/cate-10',
            child: [],
          },
          {
            id: 'cate-11',
            title: '寵物用品',
            link: '/category/cate-11',
            child: [],
          },
          {
            id: 'cate-12',
            title: '絕版出清',
            link: '/category/cate-12',
            child: [],
          },
        ],
      });

      return next(action);
    }

    case 'MID_GET_HOLA_Banner_A4': {
      store.dispatch({
        type: 'GET_HOLA_Banner_A4',
        payload: [
          { link: '/', text: 'HOLA_Banner_A4檔期優惠1' },
          { link: '/', text: '『輪播』檔期優惠文字2' },
          { link: '/', text: '檔期優惠『輪播』文字3' },
        ],
      });
      return next(action);
    }

    case 'MID_GET_LIKE': {
      store.dispatch({
        type: 'GET_LIKE',
        payload: 13,
      });
      return next(action);
    }

    case 'MID_GET_CART': {
      store.dispatch({
        type: 'GET_CART',
        payload: 26,
      });
      return next(action);
    }

    case 'MID_GET_SEARCH_SUGGEST': {
      const keyword = action.payload;
      store.dispatch({
        type: 'GET_SEARCH_SUGGEST',
        payload: keyword
          ? {
              search: [1, 2, 3, 4, 5, 6].map((index) => ({
                id: `searchSuggest-${index}`,
                name: '三人座沙發',
                link: '/',
              })),
              product: [1, 2, 3, 4, 5, 6].map((index) => ({
                id: `productSuggest-${index}`,
                image: 'https://fakeimg.pl/60x60/?text=product1',
                link: '/',
                name: '康妮亞沙發床',
                description: '-深藍SF3378-24',
              })),
            }
          : {
              search: [],
              product: [],
            },
      });
      return next(action);
    }

    case 'MID_GET_ACCOUNT': {
      // 以accountId是否為null，判斷登入狀態
      const accountInfo = { accountId: 1, accountName: '王馬克' };
      const actionList = [
        { id: 'accountCover1', name: '訂單管理', link: '/', mark: false },
        { id: 'accountCover2', name: '電子錢包', link: '/', mark: false },
        { id: 'accountCover3', name: '點數查詢', link: '/', mark: false },
        { id: 'accountCover4', name: '折價券查詢', link: '/', mark: false },
        { id: 'accountCover5', name: '我的帳戶', link: '/', mark: false },
      ];

      if (!!accountInfo.accountId) {
        actionList.push({ id: 'accountCover6', name: '登出', type: 'logout', mark: true });
      }

      store.dispatch({
        type: 'GET_ACCOUNT',
        payload: {
          ...accountInfo,
          actionList,
        },
      });

      return next(action);
    }

    case 'MID_LOGOUT': {
      // 以accountId是否為null，判斷登入狀態
      store.dispatch({
        type: 'SET_LOGOUT',
        payload: {
          accountId: null,
          accountName: '',
        },
      });

      return next(action);
    }

    default:
      return next(action);
  }
};

export default headerMiddleware;

import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import headerMiddleware from '@/js/middleware/headerMiddleware';
import categoriesMiddleware from '@/js/middleware/categoriesMiddleware';
import subcategoryMiddleware from '@/js/middleware/subcategoryMiddleware';
import childCategoryMiddleware from '@/js/middleware/childCategoryMiddleware';
import searchMiddleware from '@/js/middleware/searchMiddleware';
import productMiddleware from '@/js/middleware/productMiddleware';
import reducers from '@/js/reducers';

const enhancer = applyMiddleware(
  headerMiddleware,
  categoriesMiddleware,
  subcategoryMiddleware,
  childCategoryMiddleware,
  searchMiddleware,
  productMiddleware,
  thunk,
);

export default createStore(reducers, composeWithDevTools(enhancer));

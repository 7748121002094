const initState = {
  breadcrumb: {},
  productDetail: {},
  productAddOn: [],
  productTabs: {},
  productComment: {},
  productSeries: {},
  productCombo: {},
  productAlsoViewed: {},
  productBannerRecent: {},
  productBannerRelated: {},
};

export function productState(state = initState, action) {
  switch (action.type) {
    case 'GET_PRODUCT_BREADCRUMB': {
      return {
        ...state,
        breadcrumb: action.payload,
      };
    }
    case 'GET_PRODUCT_DETAIL': {
      return {
        ...state,
        productDetail: action.payload,
      };
    }
    case 'GET_PRODUCT_ADD_ON': {
      return {
        ...state,
        productAddOn: action.payload,
      };
    }
    case 'SET_PRODUCT_LIKED': {
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          ...action.payload,
        },
      };
    }
    case 'GET_PRODUCT_TABS': {
      return {
        ...state,
        productTabs: action.payload,
      };
    }
    case 'GET_PRODUCT_COMMENT': {
      return {
        ...state,
        productComment: action.payload,
      };
    }
    case 'GET_PRODUCT_SERIES': {
      return {
        ...state,
        productSeries: action.payload,
      };
    }
    case 'GET_PRODUCT_COMBO': {
      return {
        ...state,
        productCombo: action.payload,
      };
    }
    case 'GET_PRODUCT_ALSO_VIEWED': {
      return {
        ...state,
        productAlsoViewed: action.payload,
      };
    }
    case 'GET_PRODUCT_BANNER_RECENT': {
      return {
        ...state,
        productBannerRecent: action.payload,
      };
    }

    case 'UPDATE_PRODUCT_BANNER_RECENT': {
      const { index, data } = action.payload;
      const productArray = state.productBannerRecent.products;
      productArray[index] = {
        ...productArray[index],
        ...data,
      };
      return {
        ...state,
        productBannerRecent: { ...state.productBannerRecent, products: productArray },
      };
    }

    case 'GET_PRODUCT_BANNER_RELATED': {
      return {
        ...state,
        productBannerRelated: action.payload,
      };
    }

    default:
      return state;
  }
}
export default productState;

const productMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'MID_GET_PRODUCT_BREADCRUMB': {
      store.dispatch({
        type: 'GET_PRODUCT_BREADCRUMB',
        payload: {
          pc: [
            { name: '居家用品', link: '/' },
            { name: '寢具床墊', link: '/' },
            { name: '麵包屑3', link: '/' },
            { name: '麵包屑4', link: '/' },
          ],
          mb: [{ name: '居家用品', link: '/' }],
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_TABS': {
      store.dispatch({
        type: 'GET_PRODUCT_TABS',
        payload: {
          pc_data: [
            {
              id: 'info',
              name: '商品詳情',
              content:
                '<p>實用又貴氣的多功能邊櫃,它的抽屜是可以轉換使用的，因此為您帶來了方便又省時為了確保抽屜的耐用性，在邊櫃的每個抽屜都安裝了緩衝關閉系統，以防止抽屜猛烈關閉並抽屜的前後兩側顏色有所不同，分別是黑色或橡木色，您可以依據自己的喜好的顏色擺放抽屜的兩側的滑動架裝置了由柔軟且毛絨般的材料製作而成的軟墊為了增添邊櫃的美感，工匠們以精美的工藝設計與單板木料呈現 實心美國白橡木製成 櫃體腳需組裝\n- 商品尺寸:長180X寬50X高75公分\n- 商品重量:74.2公斤\n- 因商品屬性關係，將有專人與您約定送貨時間</p><img src="https://fakeimg.pl/1000x1000/003049/?text=image" /><img src="https://fakeimg.pl/1000x746/d62828/?text=image" /><img src="https://fakeimg.pl/1000x668/f77f00/?text=image" /><img src="https://fakeimg.pl/1000x2958/fcbf49/?text=image" />',
            },
            {
              id: 'spec',
              name: '商品規格',
              content:
                '<p>● 條碼: 4550182011456\n●材質: 結構材料: 本體: 合板、金屬(鋼)、天然木/躺椅底座 : 金屬(鋼)、合板/表布: 本體 : 聚酯纖維 墊子: 棉/填充材: 本體 : 聚氨酯發泡材、鋼製彈簧(S型彈簧)、鋼絲/背、座墊填充物: 羽毛、聚氨酯發泡材、獨立筒彈簧\n●尺寸: W225xD150xH75cm\n●產地: 中國</p>',
            },
            {
              id: 'notice',
              name: '購買須知',
              content:
                '<p>● 此為沙發本體。\n● 布套、椅腳(木製腳/12cm/原色、木製腳/12cm/棕色、鋼製腳/12cm/黑色) 需另外選購!\n● 本商品沙發腳務必訂購2組方能使用。</p>',
            },
            {
              id: 'reminder',
              name: '購買貼心叮嚀',
              content:
                '<p>※大型家具屬特殊配送商品，恕不符合 7個工作天內送達(不含週六日)的規定，請詳閱訂購須知及配送服務說明</p>',
            },
          ],
          mb_data: [
            {
              id: 'info',
              name: '商品詳情',
              content:
                '<p>實用又貴氣的多功能邊櫃,它的抽屜是可以轉換使用的，因此為您帶來了方便又省時為了確保抽屜的耐用性，在邊櫃的每個抽屜都安裝了緩衝關閉系統，以防止抽屜猛烈關閉並抽屜的前後兩側顏色有所不同，分別是黑色或橡木色，您可以依據自己的喜好的顏色擺放抽屜的兩側的滑動架裝置了由柔軟且毛絨般的材料製作而成的軟墊為了增添邊櫃的美感，工匠們以精美的工藝設計與單板木料呈現 實心美國白橡木製成 櫃體腳需組裝\n- 商品尺寸:長180X寬50X高75公分\n- 商品重量:74.2公斤\n- 因商品屬性關係，將有專人與您約定送貨時間</p><img src="https://fakeimg.pl/1000x1000/003049/?text=image" /><img src="https://fakeimg.pl/1000x746/d62828/?text=image" /><img src="https://fakeimg.pl/1000x668/f77f00/?text=image" /><img src="https://fakeimg.pl/1000x2958/fcbf49/?text=image" />',
            },
            {
              id: 'spec',
              name: '商品規格',
              content:
                '<p>● 條碼: 4550182011456\n●材質: 結構材料: 本體: 合板、金屬(鋼)、天然木/躺椅底座 : 金屬(鋼)、合板/表布: 本體 : 聚酯纖維 墊子: 棉/填充材: 本體 : 聚氨酯發泡材、鋼製彈簧(S型彈簧)、鋼絲/背、座墊填充物: 羽毛、聚氨酯發泡材、獨立筒彈簧\n●尺寸: W225xD150xH75cm\n●產地: 中國</p>',
            },
            {
              id: 'notice',
              name: '購買須知',
              content:
                '<p>● 此為沙發本體。\n● 布套、椅腳(木製腳/12cm/原色、木製腳/12cm/棕色、鋼製腳/12cm/黑色) 需另外選購!\n● 本商品沙發腳務必訂購2組方能使用。</p>',
            },
            {
              id: 'reminder',
              name: '購買貼心叮嚀',
              content:
                '<p>※大型家具屬特殊配送商品，恕不符合 7個工作天內送達(不含週六日)的規定，請詳閱訂購須知及配送服務說明</p>',
            },
          ],
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_COMMENT': {
      store.dispatch({
        type: 'GET_PRODUCT_COMMENT',
        payload: {
          pc_title: '客戶評價',
          pc_rate: 4.5,
          pc_btn_link: '/',
          pc_btn_text: '我要評論',
          pc_comments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => ({
            id: `product-comment-${num}`,
            name: '姓*名',
            rate: 4,
            comment:
              '文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字',
            date: '2022.12.31',
          })),
          mb_title: '客戶評價',
          mb_rate: 4.5,
          mb_btn_link: '/',
          mb_btn_text: '我要評論',
          mb_comments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => ({
            id: `product-comment-${num}`,
            name: '姓*名',
            rate: 4,
            comment:
              '文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字',
            date: '2022.12.31',
          })),
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_SERIES': {
      store.dispatch({
        type: 'GET_PRODUCT_SERIES',
        payload: {
          pc_title: '系列商品',
          pc_title_link: '/',
          pc_data: [1, 2, 3, 4, 5, 6, 7, 8].map((num) => ({
            id: `sku-series-${num}`,
            image: `https://fakeimg.pl/396x396/?text=slide_${num}`,
            link: '/',
            productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
          })),
          mb_title: '系列商品',
          mb_title_link: '/',
          mb_data: [1, 2, 3, 4, 5, 6, 7, 8].map((num) => ({
            id: `sku-series-${num}`,
            image: `https://fakeimg.pl/396x396/?text=slide_${num}`,
            link: '/',
            productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
          })),
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_COMBO': {
      store.dispatch({
        type: 'GET_PRODUCT_COMBO',
        payload: {
          pc_plan_title_A1: '家企師計畫',
          pc_plan_title_A2: `完美居家提案\n解決家裡大小事\n生活體驗更加分`,
          pc_plan_title_B1: `我們的\n設計師\n推薦`,
          pc_plan_title_B2: '',
          pc_plan_image: 'https://fakeimg.pl/194x194/?text=image',
          pc_product_L1: {
            image: 'https://fakeimg.pl/396x396/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          pc_product_L2: {
            image: 'https://fakeimg.pl/396x396/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          pc_product_S1: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          pc_product_S2: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          pc_product_S3: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          pc_product_S4: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          mb_plan_title_A1: '',
          mb_plan_title_A2: '',
          mb_plan_title_B1: `我們的\n設計師\n推薦`,
          mb_plan_title_B2: '點亮家居空間靈感',
          mb_plan_image: 'https://fakeimg.pl/194x194/?text=image',
          mb_product_L1: {
            image: 'https://fakeimg.pl/396x396/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          mb_product_L2: {
            image: 'https://fakeimg.pl/396x396/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          mb_product_S1: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          mb_product_S2: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          mb_product_S3: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
          mb_product_S4: {
            image: 'https://fakeimg.pl/194x194/?text=image',
            name: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
            link: '/',
          },
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_ALSO_VIEWED': {
      store.dispatch({
        type: 'GET_PRODUCT_ALSO_VIEWED',
        payload: {
          pc_title: '其他人也買了',
          pc_title_link: '/',
          pc_data: [1, 2, 3, 4, 5, 6, 7, 8].map((num) => ({
            id: `sku-also-viewed-${num}`,
            image: `https://fakeimg.pl/396x396/?text=slide_${num}`,
            link: '/',
            productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
          })),
          mb_title: '其他人也買了',
          mb_title_link: '/',
          mb_data: [1, 2, 3, 4, 5, 6, 7, 8].map((num) => ({
            id: `sku-also-viewed-${num}`,
            image: `https://fakeimg.pl/396x396/?text=slide_${num}`,
            link: '/',
            productName: '品名品名品名品名品名品名品名品名品名品名品名品名品名',
            price: '$123,456',
          })),
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_BANNER_RECENT': {
      store.dispatch({
        type: 'GET_PRODUCT_BANNER_RECENT',
        payload: {
          pc_title: '最近瀏覽過的商品',
          mb_title: '最近瀏覽過的商品',
          products: Array(11)
            .fill(0)
            .map((_, index) => {
              const titleArray = ['https://fakeimg.pl/120x18/?text=logo', ''];
              const priceArray = ['網購價 $123,456', ''];
              const price = priceArray[Math.floor(Math.random() * priceArray.length)];
              const originPrice = !!price ? '原價 $323,456' : '網購價 $223,456';
              const materialsArray = [
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                  {
                    id: `productId${index}-7`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_7',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                  {
                    id: `productId${index}-5`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_5',
                  },
                  {
                    id: `productId${index}-6`,
                    image: 'https://fakeimg.pl/340x340/a5a58d?text=image_6',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                  {
                    id: `productId${index}-3`,
                    image: 'https://fakeimg.pl/340x340/ffe8d6?text=image_3',
                  },
                  {
                    id: `productId${index}-4`,
                    image: 'https://fakeimg.pl/340x340/b7b7a4?text=image_4',
                  },
                ],
                [
                  {
                    id: `productId${index}-1`,
                    image: 'https://fakeimg.pl/340x340/cb997e?text=image_1',
                  },
                  {
                    id: `productId${index}-2`,
                    image: 'https://fakeimg.pl/340x340/ddbea9?text=image_2',
                  },
                ],
                [],
              ];
              const productActivityArray = [
                '★HOLA 春養新 鍋具餐具區單筆滿$1000現折$100 (可累折)',
                '',
              ];
              const marketActivityArray = ['愛家卡點數回饋最高 25 點', ''];
              return {
                id: `productId${index}-1`,
                image: 'https://fakeimg.pl/340x340/?text=recent',
                hoverImage: 'https://fakeimg.pl/340x340/000000/?text=hover',
                title: titleArray[Math.floor(Math.random() * titleArray.length)],
                name: 'Le Creuset 琺瑯單柄調理鍋16cm 貝殼粉',
                price,
                isLiked: false,
                link: '/',
                originPrice,
                productActivity:
                  productActivityArray[Math.floor(Math.random() * productActivityArray.length)],
                marketActivity:
                  marketActivityArray[Math.floor(Math.random() * marketActivityArray.length)],
                materials: materialsArray[Math.floor(Math.random() * materialsArray.length)],
              };
            }),
        },
      });
      return next(action);
    }

    case 'MID_GET_PRODUCT_BANNER_RELATED': {
      store.dispatch({
        type: 'GET_PRODUCT_BANNER_RELATED',
        payload: {
          pc_title: '相關分類推薦',
          pc_data: [
            { id: 'relatedCategory1', name: 'Contemporary Furniture', link: '/' },
            { id: 'relatedCategory2', name: 'Upholstered Furniture', link: '/' },
            { id: 'relatedCategory3', name: 'Recliners', link: '/' },
            { id: 'relatedCategory4', name: 'Reclaimed Wood Tables', link: '/' },
            { id: 'relatedCategory5', name: 'Sofa Slipcovers', link: '/' },
            { id: 'relatedCategory6', name: 'Wicker Furniture', link: '/' },
          ],
          mb_title: '相關分類推薦',
          mb_data: [
            { id: 'relatedCategory1', name: 'Contemporary Furniture', link: '/' },
            { id: 'relatedCategory2', name: 'Upholstered Furniture', link: '/' },
            { id: 'relatedCategory3', name: 'Recliners', link: '/' },
            { id: 'relatedCategory4', name: 'Reclaimed Wood Tables', link: '/' },
            { id: 'relatedCategory5', name: 'Sofa Slipcovers', link: '/' },
            { id: 'relatedCategory6', name: 'Wicker Furniture', link: '/' },
          ],
        },
      });
      return next(action);
    }

    default:
      return next(action);
  }
};

export default productMiddleware;
